import {Component, EventEmitter, Input, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NavigationComponent, NavigationSectionGroup} from './navigation.component';
import {takeUntil} from 'rxjs/operators';
import {merge, of} from 'rxjs';

@Component({
    selector: '[appNavigation=tab]',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class TabNavigationComponent extends NavigationComponent {
    @Input() hasPagingButtons = true;
    @Input() activeTab: string;
    @Output() navigationEnds = new EventEmitter<NavigationSectionGroup | string>();
    @ViewChild('topAnchor') topAnchor;
    ngAfterViewInit() {
        merge(of(null), this.sections$).pipe(takeUntil(this.destroy$)).subscribe(() => this.init());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.activeTab) this.jumpTo(changes.activeTab.currentValue, true, false);
    }

    init() {
        const sections = this.sections;

        if (!sections.includes(this.activeSection)) this.jumpTo(this.activeTab || sections[0], false);
    }

    jumpTo(section: NavigationSectionGroup | string, scroll = true, emitChanges = true) {
        const target: NavigationSectionGroup = typeof section === 'string' ? this.sections.find(x => x.label === section) : section;
        if (target) {
            this.activeSection = target;
            this.sections.forEach(sg => sg.sections.forEach(s => {
                s.active = sg === this.activeSection;
                s.elRef.nativeElement.style.display = s.active ? 'block' : 'none';
            }));
            if (scroll) {
                this.topAnchor?.nativeElement.scrollIntoView();
            }
            if (emitChanges) this.navigationEnds.emit(target.label);
        }
    }
}
